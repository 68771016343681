@import '@/_styles/import';
.root {
  display: flex;
  padding: 30px;

  @include font($size: rem(17), $color: $grey-text, $line-height: rem(25), $weight: 700);

  letter-spacing: 0;

  .icon {
    :global(.Icon),
    :global(.Icon span),
    svg {
      display: block;
      width: 24px;
      height: 24px;
      color: $black;
    }
  }

  .title {
    color: $black;

    &.withIcon {
      margin-left: 9px;
    }
  }
}
