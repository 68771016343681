@import '@/_styles/import';
$iconSize: 30px;
$borderWidth: 2px;

.root {
  position: relative;
  padding: calc(32px + ($iconSize - $borderWidth) / 2) 32px 32px;
  text-align: center;

  &::before {
    content: '';

    position: absolute;
    inset: calc(($iconSize - $borderWidth) / 2) 0 0 0; /* stylelint-disable-line shorthand-property-no-redundant-values */

    background-color: var(--warning-box-bg-color);
    border: $borderWidth solid var(--warning-box-border-color);
    border-radius: 4px;
  }

  &:global(._error) {
    --warning-box-bg-color: #{$error-bg};
    --warning-box-border-color: #{$error};
  }

  &:global(._warning) {
    --warning-box-bg-color: #{$warning-bg};
    --warning-box-border-color: #{$warning};
  }

  &:global(._info) {
    --warning-box-bg-color: #{$success-bg};
    --warning-box-border-color: #{$success};
  }
}

.rootIcon {
  position: absolute;
  top: 0;
  left: calc(50% - $iconSize/2);

  width: $iconSize;
  height: $iconSize;

  background-color: var(--warning-box-border-color);
  border-radius: 50%;

  svg {
    width: 80%;
    height: 80%;
    margin: 10%;
    fill: $white;
  }
}

.title {
  position: relative;
  margin: 0 0 16px;
  color: $black;

  @include font($size: rem(13), $weight: 700, $line-height: 1.3);
}

.text {
  position: relative;
  margin: 0;
  color: $black;

  @include font($size: rem(13), $weight: 400, $line-height: rem(20));
}
